<template>
    <div>
        <Loading type="line" v-if="loading" />
        <div ref="scrollContainer" class="div-main">
            <draggable tag="div" v-model="columns" class="side-by-side div-column">
                <div v-for="column in columns" class="side-by-side div-kanban">
                    <div class="div-title">
                        <b-row>
                            <b-col sm="10">
                                <InputTextEdit :enter="updateStep" :params="column" v-model="column.name" />
                            </b-col>
                            <b-col sm="2" v-if="column.cards.length == 0">
                                <div class="text-right div-close" @click="remove(column)">
                                    <i class="fa-sharp fa-solid fa-trash"></i>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="div-add-card" @click="addCard(column)">
                        <i class="fa-solid fa-circle-plus"></i>
                    </div>
                    <ScrollBar :minHeight="browserHeightConditional" :maxHeight="browserHeightConditional">
                        <div class="div-scroll">
                            <draggable :list="column.cards" :animation="200" ghost-class="ghost-card" group="cards"
                                @end="handleDragEnd">
                                <Card v-for="(card) in column.cards" :key="card.id" :card="card" :params="card"
                                    :clicked="editCard" />
                            </draggable>
                        </div>
                    </ScrollBar>
                </div>
                <div class="side-by-side div-add-kanban" v-if="!loading">
                    <div @click="addStep">
                        <i class="fa-solid fa-circle-plus"></i>
                        <span class="title-column">Adicionar etapa</span>
                    </div>
                </div>
            </draggable>
        </div>
        <Modal title="Cartão" :width="700" :height="750" v-show="showModal('card')">
            <CardCreate :step="stepSelected" />
        </Modal>
        <Modal :title="cardSelected.roomName" :width="900" :height="750" v-show="showModal('edit-card')"
            :onHideModal="onHideModal">
            <CardEdit v-if="showModal('edit-card')" :card="cardSelected" />
        </Modal>

    </div>
</template>
  
<script>

import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";

import draggable from "vuedraggable";
import Card from './Card.vue'
import CardCreate from '../card/CardCreate.vue'
import CardEdit from '../card/CardEdit.vue'

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "Kanban",
    components: {
        InputTextEdit,
        Modal,
        draggable,
        Card,
        CardCreate,
        Loading, CardEdit, ScrollBar
    },
    data() {
        return {
            id: this.$route.params.id,
            urlCreate: "/api/v1/planner/step/create",
            urlUpdate: "/api/v1/planner/step/update",
            urlUpdateCard: "/api/v1/planner/card/update-step",
            urlDelete: "/api/v1/planner/step/delete",
            urlGetAll: "/api/v1/planner/step/get-all",
            urlOrderAll: "/api/v1/planner/step/order-all",
            columnsLoadFirst: [],
            stepSelected: {},
            cardSelected: {},
            columns: [],
            loading: false,
            isDragging: false,
            sliderPosition: 0,
            browserHeight: 0,
        };
    },
    mounted() {
        this.getAll();

        this.browserHeight = window.innerHeight;
        window.addEventListener('resize', this.updateBrowserHeight);
    },
    computed: {
        ...mapGetters("generic", ["event", "showModal"]),
        hasChange() {
            if (this.columns.length !== this.columnsLoadFirst.length) {
                return true;
            }

            for (let i = 0; i < this.columns.length; i++) {
                if (this.columns[i].id !== this.columnsLoadFirst[i].id) {
                    return true;
                }
            }

            return false;
        },
        browserHeightConditional() {
            return this.browserHeight - 400;
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "openModal", "hideModal"]),
        ...mapActions("generic", ["getApi", "postApi", "putApi", "deleteAllApi", "orderAllApi"]),
        addCard(column) {
            this.stepSelected = this.findTaskByTitle(column.id);
            this.openModal("card");
        },
        findTaskByTitle(titleToFind) {
            for (const column of this.columns) {
                if (column.id === titleToFind) {
                    return column;
                }
            }
            return null;
        },
        getAll() {
            this.loading = true;
            let params = {
                url: this.urlGetAll,
                obj: {
                    any: this.id,
                }
            };
            this.getApi(params).then((response) => {
                this.columnsLoadFirst = response.content.data;
                this.columns = response.content.data;
                this.loading = false;
            });
        },
        addStep() {
            let params = {
                url: this.urlCreate, obj: {
                    name: "Novo",
                    frameId: this.id,
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.columns.push({ id: response.content.id, name: "Novo", cards: [] });
                }
            });
        },
        updateStep(item) {
            let params = {
                url: this.urlUpdate, obj: {
                    id: item.id,
                    name: item.name,
                },
                notNotifyToast: true
            };
            this.putApi(params).then((response) => {
                if (!response.success) {
                    this.getAll();
                }
            });
        },
        remove(item) {
            let params = {
                url: this.urlDelete,
                selected: [item.id],
            };
            this.deleteAllApi(params).then((response) => {
                if (response.success) {
                    this.getAll();
                }
            });
        },
        orderAll() {
            let listIds = [];

            this.columns.forEach((item) => {
                listIds.push(item.id);
            });

            let obj = { listIds: listIds };
            let params = { url: this.urlOrderAll, obj: obj };

            this.orderAllApi(params).then((response) => { this.getAll(); });
        },
        handleDragEnd(event) {
            var { _underlying_vm_ } = JSON.parse(JSON.stringify(event.item));
            var cardId = _underlying_vm_.id;
            var stepId = "";

            this.columns.forEach(column => {
                column.cards.forEach(card => {
                    if (card.id === cardId) {
                        stepId = column.id;
                    }
                });
            });

            let params = {
                url: this.urlUpdateCard, obj: {
                    id: cardId,
                    any: stepId,
                },
                notNotifyToast: true
            };
            this.putApi(params).then(() => { });
        },
        editCard(card) {
            this.cardSelected = card;
            this.openModal("edit-card");
        },
        onHideModal() {
            this.openModal("");
        },
        updateBrowserHeight() {
            this.browserHeight = window.innerHeight;
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "cardCreated"
                    || event.name == "responsibleUserUpdated"
                    || event.name == "cardRemoved") this.getAll();
            },
            deep: true,
        },
        columns: {
            handler() {
                if (this.hasChange) this.orderAll();
            },
            deep: true,
        },
    }
};
</script>
  
<style scoped>
.div-main {
    margin-top: 30px;
    overflow-x: auto;
}

.div-main::-webkit-scrollbar-track {
    height: 1px;
    background-color: white;
    border-radius: 20px;
}

.div-main::-webkit-scrollbar {
    height: 7px;
    background-color: #B9BABB;
    border-radius: 20px;
}

.div-main::-webkit-scrollbar-thumb {
    background-color: #B9BABB;
    border-radius: 20px;
}

.div-column {
    cursor: move;
    display: flex;
    align-items: flex-start;
}

.div-kanban {
    min-width: 350px;
    margin-right: 30px;
    padding: 20px;
    border-radius: 20px;
    background-color: #FAFAFC;
    margin-bottom: 50px;
}

.div-add-kanban {
    color: darkblue;
    min-width: 300px;
    margin-right: 30px;
    font-size: 15px;
    cursor: pointer;
    overflow-x: auto;
    white-space: nowrap;
}

.div-add-card {
    color: darkblue;
    margin-bottom: 10px;
    cursor: pointer;
}

.div-scroll {
    margin-right: 5px;
}


.div-close {
    color: red;
    font-size: 16px;
    cursor: pointer;
    opacity: 0.5;
}

.div-close:hover {
    color: red;
    opacity: 10;
}

.div-title {
    margin-bottom: 10px;
    font-size: 15px;
}

.title-column {
    font-size: 15px !important;
    margin-left: 10px;
}
</style>
  