<template>
  <div>
    <ScrollBar :minHeight="650" :maxHeight="650">
      <div class="div-info">
        <div>
          <div class="side-by-side div-user">
            <ResponsibleUser :channel="card.channel" :genericId="card.id" :multiSelected="false"
              :responsibleUser="responsibleUser" v-model="card.user" urlGet="/api/v1/adm/user/select-all"
              urlUpdate="/api/v1/planner/card/responsible-user" />
          </div>
          <div class="side-by-side">
            <Tags :cardId="card.id" :initialValue="card.listTag" />
          </div>
        </div>
        <InputTextEdit v-model="card.description" :enter="saveCard" />
      </div>
      <b-tabs v-model="tabIndex">
        <b-tab title="Nota">
          <NoteListCreateEdit :cardId="card.id" />
        </b-tab>
        <b-tab title="Lembrete">
          <ScheduleList :channel="card.channel" :cardId="card.id" :customerSupplierId="card.customerSupplier.id"
            :showScheduleCreate="true" :showFilter="false" :showButtons="false" />
        </b-tab>
        <!-- <b-tab title="Tarefa">
        <ScrollBar :minHeight="400" :maxHeight="400"></ScrollBar>
      </b-tab>-->
        <b-tab v-if="hasRuleCrm && card.channel != 0" title="Chat">
          <div class="div-scroll">
            <div v-if="card.listRoom.length > 0">
              <ButtonFilter :options="optionsRoom" :initialOption="card.listRoom[0]" v-model="room" />
              <ChatPlanner :channel="1" :roomId="room.id" :senderPhone="room.contact.mobile" />
            </div>
            <div v-else>
              Nenhuma conversa iniciada!
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </ScrollBar>
  </div>
</template>

<script>


import ButtonFilter from "@nixweb/nixloc-ui/src/component/forms/ButtonFilter";
import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import ResponsibleUser from "../../../modules/chat/responsible-user/ResponsibleUser.vue";

import Tags from '../tag/Tags.vue'
import NoteListCreateEdit from '../note/NoteListCreateEdit.vue'
import ScheduleList from '../../chat/schedule/ScheduleList.vue'
import ChatPlanner from "../../chat/whatsapp/ChatPlanner.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";


export default {
  name: "CardCreate",
  props: {
    card: Object,
  },
  components: { ButtonFilter, Button, InputText, InputTextEdit, Select, ScrollBar, ResponsibleUser, ChatPlanner, ScheduleList, NoteListCreateEdit, Tags },
  data() {
    return {
      formName: "CardConversationCreate",
      urlUpdate: "/api/v1/planner/card/update",
      tabIndex: 0,
      room: this.card.listRoom[0],
      responsibleUser: {},
    };
  },
  mounted() {
    this.responsibleUser = this.card.user
  },
  computed: {
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule", "hasPermission"]),
    hasRuleCrm() {
      var crm = this.hasPermission("CRM");
      if (crm) {
        return crm.includes("chat");
      }
      return false;
    },
    hasRuleSupport() {
      var support = this.hasPermission("Support");
      if (support) {
        return support.includes("chat");
      }
      return false;
    },
    optionsRoom() {
      return this.card.listRoom.map(room => ({
        title: room.contact.name,
        classIcon: 'fa-solid fa-user',
        value: room,
      }));
    },
  },
  methods: {
    ...mapActions("generic", ["putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    saveCard() {
      this.card.tags = [];
      let params = { url: this.urlUpdate, obj: this.card, notNotifyToast: true };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "cardUpdated" });
        }
        this.removeLoading(["saveCard"]);
      });
    }
  },
  watch: {
    'card.user': {
      handler() {
        this.addEvent({ name: "responsibleUserUpdated" });
      },
      deep: true,
    },
  }
};
</script>

<style scoped>
.div-info {
  margin-bottom: 15px;
  font-size: 15px;
}

.div-scroll {
  height: 470px;
}

.div-user {
  margin-right: 15px;
}
</style>