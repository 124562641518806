<template>
    <div>
        <Panel :module="module" layout="large" :showFilter="false" :showSearch="false" :showButtons="false">
            <div slot="content-main">
                <br>
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="4" xl="3">
                        <div class="div-filter-user">
                            <ResponsibleUser :responsibleUser="userLogged.user" :multiSelected="true"
                                urlGet="/api/v1/adm/user/select-all" v-model="usersFilter" />
                        </div>
                    </b-col>
                </b-row>
                <Kanban />
            </div>
        </Panel>
    </div>
</template>

<script>

import ButtonGroup from "@nixweb/nixloc-ui/src/component/forms/ButtonGroup";
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import ResponsibleUser from "../../../components/modules/chat/responsible-user/ResponsibleUser.vue";

import Kanban from '../../../components/modules/planner/kanban/Kanban.vue'

import { mapMutations, mapGetters, mapState } from "vuex";

export default {
    name: "FrameView",
    components: { ButtonGroup, Panel, Loading, Button, Modal, Kanban, ResponsibleUser },
    data() {
        return {
            module: this.$route.params.name,
            usersFilter: [],
        }
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        ...mapGetters("generic", ["showModal", "event"]),
        ...mapState("user", ["userLogged"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    },
}
</script>
<style scoped></style>