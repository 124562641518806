<template>
    <div>
        <vue-advanced-chat v-show="messages.length > 0 && !showTemplate" height="420px" :show-search="true"
            :current-user-id="'user-internal'" :rooms="JSON.stringify(rooms)" :rooms-loaded="true"
            :messages="JSON.stringify(messages)" :messages-loaded="false" @send-message="sendMessage($event.detail[0])"
            show-new-messages-divider="false" rooms-list-opened="false" :text-messages="JSON.stringify(textMessages)"
            @fetch-messages="fetchMessages($event.detail[0])" :styles="JSON.stringify(style.light)" @open-file="openFile">
            <div slot="room-header-avatar"></div>
            <div slot="room-header-info">
                <div class="side-by-side">
                    <i class="fa-brands fa-whatsapp icon-whats"></i>
                </div>
                <div class="side-by-side div-whats">
                    {{ senderPhone }}
                </div>
                <span class="div-open-template" v-if="isNeedTemplate" @click="showTemplate = true">
                    - <i class="warning fa-sharp fa-solid fa-triangle-exclamation"></i>
                    Template necessário
                </span>
            </div>
            <div slot="spinner-icon-infinite-messages"></div>
            <div slot="spinner-icon-messages">
                <div v-if="messages.length == 0">
                    <span class="not-message">
                        Nenhuma mensagem
                    </span>
                </div>
                <div v-else class="text-center">
                    <vue-loading type="bubbles" color="#EA7F29" :size="{ width: '60px', height: '60px' }"></vue-loading>
                </div>
            </div>
            <div slot="send-icon">
                <div class="div-btn-send">
                    <Button _key="btnSend" type="primary" classIcon="fa-solid fa-paper-plane-top" size="small" />
                </div>
            </div>
            <div slot="toggle-icon"></div>
        </vue-advanced-chat>
        <div v-if="showTemplate">
            <div class="div-back-chat text-right" @click="showTemplate = false">
                Voltar
            </div>
            <Template :templates="templates" :sendTemplate="sendTemplate" />
        </div>
        <div v-if="messages.length == 0">
            Nenhuma mensagem!
        </div>
    </div>
</template>

<script>


import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import Template from '../template/Template.vue'

import { register } from 'vue-advanced-chat'
import { style, language } from './config'

import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

register();

export default {
    name: "ChatPlanner",
    props: {
        channel: Number,
        roomId: String,
        senderPhone: String
    },
    components: {
        Button,
        Modal,
        Loading,
        Template,
    },
    data() {
        return {
            rooms: [{ "roomId": "" }],
            isLead: false,
            senderEmail: "",
            urlGetTemplate: "/api/v1/gupshup/get-template",
            urlFile: "/api/v1/adm/file-upload/upload",
            urlCreateMessage: "/api/v1/chat/message/create",
            urlCreateMessageTemplate: "/api/v1/chat/message/create-template",
            urlGetMessage: "/api/v1/chat/message/get-all",
            style: style,
            baseParams: {
                search: undefined,
                filter: "contains",
                currentPage: 1,
                totalPerPage: 200,
            },
            totalRecords: 0,
            textMessages: language,
            messages: [],
            messagesLoaded: false,
            loadingSendMessage: false,
            loadingChat: true,
            templates: [],
            showTemplate: false,
            loadingHeader: false,
        }
    },
    mounted() {
        this.getTemplate();
        this.fetchMessages();
        let self = this;
        setTimeout(function () {
            self.loadingChat = false;
        }, 500);
    },
    computed: {
        ...mapState("user", ["userLogged"]),
        ...mapState("chat", ["rooms"]),
        ...mapGetters("user", ["hasRule", "hasPermission"]),
        ...mapGetters("chat", ["isRoomOpen", "usersFilter"]),
        ...mapGetters("generic", ["event", "showModal"]),
        isNeedTemplate() {
            var isNeed = this.messages.find(message => {
                return message.isNeedTemplate === true
            })
            if (isNeed || this.messages.length == 0) return true;
            return false;
        },
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["postApi", "postFileApi", "getApi"]),
        getTemplate() {
            let params = { url: this.urlGetTemplate, obj: { any: this.channel } };
            this.getApi(params).then((response) => {
                this.templates = response.content.templates;
            });
        },
        fetchMessages() {
            setTimeout(async () => {

                this.loadingHeader = true;
                this.baseParams.currentPage = 1;
                this.messages = [];
                this.messages = await this.getMessages();

            }, 0);
        },
        async getMessages() {
            let params = { url: this.urlGetMessage, obj: { any: this.roomId, ...this.baseParams } };
            const response = await this.getApi(params);
            this.totalRecords = response.content.totalRecords;

            let self = this;
            setTimeout(function () {
                self.loadingHeader = false;
            }, 300);

            return response.content.data;
        },
        async receivedOrNewMessage() {
            this.baseParams.currentPage = 1;
            this.messages = await this.getMessages();
        },
        sendMessage(message) {
            if (this.isNeedTemplate) {
                var message = {
                    _id: 7890,
                    content: 'Sua sessão de mensagens acabou, é necessário enviar um template',
                    system: true,
                    isNeedTemplate: true
                };
                this.messages.push(message);
                this.removeLoading(["btnSend"]);
            } else {
                this.loadingSendMessage = true;
                if (message.files) {
                    var self = this;
                    message.files.forEach(file => {
                        var fileName = `${file.name}.${file.extension}`;
                        self.upload(file, fileName);
                    });
                }
                else {
                    this.addNewMessage(message, "");
                }
            }
        },
        addNewMessage(message, fileName, urlFile) {
            this.loadingSendMessage = true;
            let params = {
                url: this.urlCreateMessage,
                obj: {
                    channel: this.channel,
                    content: fileName ? "" : message.content,
                    conversationId: this.roomId,
                    senderPhone: this.senderPhone,
                    userId: this.userLogged.user.id,
                    userName: this.userLogged.user.userName,
                    photo: this.userLogged.user.photo,
                    fileName: fileName,
                    urlFile: urlFile,
                },
                notNotifyToast: true
            };
            this.postApi(params).then(() => {
                this.loadingSendMessage = false;
                this.removeLoading(["btnSend"]);
            });
        },
        sendTemplate(template) {
            this.loadingSendMessage = true;
            let params = {
                url: this.urlCreateMessageTemplate,
                obj: {
                    channel: this.channel,
                    templateId: template.id,
                    content: template.content,
                    params: template.params,
                    conversationId: this.roomId,
                    senderPhone: this.senderPhone,
                    userId: this.userLogged.user.id,
                    userName: this.userLogged.user.userName,
                    photo: this.userLogged.user.photo,
                },
                notNotifyToast: true
            };
            this.postApi(params).then(() => {
                this.showTemplate = false;
                this.removeLoading(["btnSendTemplate"]);
            });
        },
        upload(file, fileName, message) {
            let params = {
                url: this.urlFile,
                file: new File([file.blob], fileName),
                container: "nixloc-gupshup",
            };
            this.postFileApi(params).then((response) => {
                if (response.success) {
                    this.addNewMessage(message, fileName, response.content);
                }
            });
        },
        openFile(param) {
            var urlFile = param.detail[0].file.file.url;
            let newUrl = urlFile.replace("download=false", "download=true");

            const downloadLink = document.createElement("a");
            downloadLink.href = newUrl;
            downloadLink.target = "_blank";
            downloadLink.click();
        },
        audio() {
            const message = require("../../../../../public/sound/message.mp3");
            var audio = new Audio(message);
            audio.play();
        },
    },
    watch: {
        event: {
            handler(event) {
                const eventName = event.name;
                const eventData = event.data;

                if (eventName == "updateStatusMessage") {
                    this.messages.forEach(msg => {
                        if (msg.id === eventData.id) {
                            msg.saved = eventData.saved;
                            msg.distributed = eventData.distributed;
                            msg.seen = eventData.seen;
                            msg.failure = eventData.failure;
                        }
                    });
                }

                if (eventName == "newMessageUserInternal") this.receivedOrNewMessage();

                if (eventName == "newMessageOrConversation") {
                    if (eventData.id == this.roomId.toUpperCase()) {
                        this.receivedOrNewMessage();
                    }
                    this.audio(eventData);
                }
            },
            deep: true,
        },
        roomId: {
            handler() {
                this.fetchMessages();
            },
            deep: true,
        },
    }
}
</script>

<style scoped>
.icon-whats {
    color: green;
    font-size: 25px;
}

.div-whats {
    margin-left: 10px;
    font-size: 16px;
}

.not-message {
    color: rgb(156, 154, 154);
    font-style: italic;
}

.div-btn-send {
    margin-bottom: 15px;
}

.div-open-template {
    cursor: pointer;
}

.div-back-chat {
    cursor: pointer;
    margin-bottom: 5px;
    color: #3f529b;
}

.div-back-chat:hover {
    text-decoration: underline;
}
</style>