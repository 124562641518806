export default class Card {
    constructor() {
        this.id = "";
        this.frameId = "";
        this.customerSupplier = { id: "", content: "" };
        this.description = "";
        this.stepId = "";
        this.userId = "";
        this.tags = [];
    }
    update(data) {
        this.id = data.id;
        this.customerSupplier = data.customerSupplier;
        this.description = data.description;
    }
}